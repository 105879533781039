import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-we-use"
    }}>{`What we use`}</h1>
    <p>{`At hybrit we use Terraform to create and manage our Infrastructure`}</p>
    <h1 {...{
      "id": "existing-infrastrucutures"
    }}>{`Existing infrastrucutures`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Client`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Cloud`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hybrit`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Google Cloud`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`K8S`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      